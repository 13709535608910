.MuiInputBase-root {
  padding: 0px !important;
}

.slider-ratio {
  aspect-ratio: 1/0.5625 !important;
  min-height: fit-content;
  overflow: hidden;
}

.slider-item {
  width: 100%;
  background-size: cover;
  background-position: center;
  aspect-ratio: 1/0.5625 !important;
}

input {
  box-shadow: none !important;
  /* border: 0px !important; */
  /* outline: 0px !important; */
  padding: 10px !important;
  font-size: 14px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

textarea {
  box-shadow: none !important;
  border: 0px !important;
  outline: 0px !important;
  padding: 10px !important;
  font-size: 14px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.h-screen {
  height: fit-content;
  /* min-height: calc(100vh - 300px); */
  /* height: calc(100vh - 64px); */
}

.card {
  padding: 8px;
  border-radius: 8px;
  /* background-color: #f2f2f2; */
  min-height: 280px;
  /* min-height: 160px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  border: 1px solid #111;
  font-size: 12px;
}

.voucher-code-text {
  font-size: 40px;
}

.card .top-left {
  left: 20px;
  top: 10px;
  position: absolute;
}

.card .top-right {
  right: 20px;
  top: 10px;
  position: absolute;
}

.card .bottom-left {
  left: 20px;
  bottom: 10px;
  position: absolute;
}

.card .bottom-right {
  right: 20px;
  bottom: 10px;
  position: absolute;
}

.sidemenu-container::-webkit-scrollbar {
  display: none;
}

.sidemenu-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sidemenu-ul {
  gap: 5px;
  color: #fff;
  padding: 5px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  border-radius: 0px 10px 10px 0px;
}

.sidemenu-ul li a {
  padding: 10px;
  width: 100%;
  display: block;
  border-radius: 10px;
  font-size: 13px;
}

.sidemenu-ul li a .menu-sub-item {
  display: none;
  height: 0px;
}

.sidemenu-ul li a.active .menu-sub-item {
  display: block;
  padding-left: 10px;
  padding-top: 5px;
  height: auto;
}

.sidemenu-ul li a:hover {
  /* background-color: #2b2bff; */
  /* background-color: rgb(30 64 175); */
  background-color: rgb(30 27 75);
}

.sidemenu-ul li a.active {
  padding: 10px;
  /* background-color: #0000bd; */
  background-color: rgb(23 37 84);
  width: 100%;
  display: block;
  border-radius: 10px;
}

.dashboard-container {
  height: calc(100vh - 64px);
  overflow-y: scroll;
}

.news-item-thumbnail {
  display: block;
  /* width: 320px; */
}

.avatar {
  width: 50px;
  height: 50px;
  background-color: #00f;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  color: #fff;
  background-position: center;
  background-size: cover;
}

.voucher-list-item span {
  display: block;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.main-footer-general {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-around;
  border-top: 1px solid #111;
  font-family: "sinar-regular";
  /* border-bottom: 1px solid #111; */
}

.main-footer-general .col {
  width: 25%;
  padding: 32px 24px 32px 24px;
  min-height: 220px;
  border-left: 1px solid #111;
}

.main-footer-general .col li {
  margin-bottom: 0.25rem !important;
}

.main-footer-general .col:nth-child(1) {
  border: none;
}

.footer-heading {
  font-family: "sinar-semibold";
  font-size: clamp(1.5rem, 3vw, 1.5rem);
  font-weight: 600 !important;
  display: block;
  margin-bottom: 1.5rem !important;
  line-height: 28.8px;
  letter-spacing: -0.32px;
}

.main-footer-general .col p {
  margin-bottom: 22px;
}

.collaborator-credits {
  font-size: 14px;
  color: #757575;
}

.container-form {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 66px;
  margin-bottom: 96px;
}

.form-card-title-container {
  text-align: center;
}

.form-card-title {
  font-size: 28px;
  font-family: "sinar-semibold";
}

.form-card-subtitle {
  font-size: 17px;
  font-family: "sinar-medium";
  font-weight: 500;
  color: #757575;
  margin-bottom: 30px;
  display: block;
}

.input-voucher {
  border: 1px solid #757575 !important;
  margin-bottom: 22px;
}

.submit-voucher.disabled {
  background-color: #d9d9d9;
  border: 1px solid #b3b3b3;
  color: #b3b3b3;
  cursor: not-allowed;
}

.input-voucher:focus {
  outline: #111 !important;
  border: 1px solid #111 !important;
}

.submit-voucher {
  height: 56px;
  width: 100%;
  background-color: #111;
  color: #fff;
  font-family: "sinar-semibold";
  border-radius: 4px;
}

.container-register {
  margin-top: 96px;
  margin-bottom: 96px;
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #111 !important;
  outline-color: #111 !important;
  border-width: 1px !important;
}

.download-success {
  font-family: "sinar-medium";
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.download-success img {
  width: 30px;
  height: auto;
  display: block;
}

.download-text {
  font-size: 17px;
  font-family: "sinar-medium";
  font-weight: 500;
  color: #757575;
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.download-button {
  cursor: pointer;
  height: 56px;
  color: #fff;
  background-color: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.container-download {
  margin-top: 96px;
  margin-bottom: 96px;
}

.error-box {
  padding: 10px;
  border-radius: 4px;
  background-color: #ffd0d0;
  border: 1px solid #e46f6f;
}

.voucher-code-text img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}

.heading-ol {
  display: block;
  font-size: 16px;
  font-weight: 500;
  font-family: "sinar-semibold";
  line-height: 120%;
}

.terms-and-conditions-box {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  font-family: "sinar-regular";
  line-height: 140%;
}
.terms-and-conditions-box p {
  font-size: 16px;
}

.redeem-badge {
  display: block;
  width: fit-content;
  padding: 5px;
  margin-top: 5px;
  border-radius: 4px;
  font-size: 12px;
}

.redeem-true {
  background-color: #e46f6f;
}
.redeem-false {
  background-color: lightgreen;
}
