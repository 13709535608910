body {
  margin: 0;
  /* font-family: "Plus Jakarta Sans", sans-serif; */
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-x: hidden;
}

@font-face {
  font-family: "sinar-bold";
  src: url("./assets/fonts/Sinar-Bold-DPd_L1HY.woff2");
  font-display: swap;
}

@font-face {
  font-family: "sinar-medium";
  src: url("./assets/fonts/Sinar-Medium-C_1KpWOv.woff2");
  font-display: swap;
}

@font-face {
  font-family: "sinar-regular";
  src: url("./assets/fonts/Sinar-Regular-Atpenqw_.woff2");
  font-display: swap;
}

@font-face {
  font-family: "sinar-semibold";
  src: url("./assets/fonts/Sinar-SemiBold-BkxqGT9K.woff2");
  font-display: swap;
}

#root {
  min-height: 100vh;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input:active,
input:focus {
  outline: none;
}

span.error-message {
  padding: 5px;
  display: block;
  font-size: 13px;
  text-align: center;
  color: #f00;
  margin-bottom: 10px;
}

span.loading-message {
  font-size: 13px;
  text-align: center;
  display: block;
  padding: 5px;
}

a.href-link {
  display: block;
  font-size: 14px;
  text-decoration: underline;
}

.border-b-1 {
  border-bottom-width: 1px;
  border-color: #000;
}

p {
  font-size: 14px;
}

.form-code-mobile {
  display: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  background: #eca3a3;
  border: 1px solid #f76c6c;
  max-width: 320px;
  padding: 10px;
  border-radius: 4px;
  color: #9a0c0c;
  font-size: 14px;
  font-family: "sinar-regular";
  line-height: 140%;
}

@media screen and (max-width: 789px) {
  .container-form {
    max-width: 320px !important;
  }
  .form-code-mobile {
    display: block;
  }

  .form-code-container {
    display: none;
  }
  .main-footer-general .col {
    width: 100% !important;
  }
  .main-footer-general {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    flex-direction: column !important;
  }
  .terms-and-conditions-box {
    width: 90% !important;
  }
  .terms-and-conditions-heading {
    padding: 20px;
  }
}
